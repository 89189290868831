<template>
  <div>
    <PageLoader :storage="appLoading" />
    <ServerError v-if="ServerError" />
    <v-snackbar v-model="showSnackBar" color="#427D2D" right :timeout="timeout">
      <v-layout wrap justify-center>
        <v-flex text-left class="align-self-center">
          <span style="color: #fff">{{ msg }}</span>
        </v-flex>
        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showSnackBar = false">
            <v-icon style="color: #fff">mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>
    <v-layout wrap justify-center pt-4>
      <v-flex xs12>
    
           <v-layout row justify-space-between align-center px-2>
            <v-flex xs12 sm6 md6 lg6 xl6>
              <span class="heading">Resource Images</span>
            </v-flex>
            <v-flex xs12 sm6 md6 lg6 xl6 pt-2 pt-lg-0 pt-sm-0 pt-md-0 class="text-right">
                <v-btn
                  color="#427D2D"
               
                    @click="adddialog = true"
                >
                  <span
                    style="
                      font-size: 12px;
                      font-family: poppinssemibold;
                      color: white;
                    "
                  >
                  Add Image
                    <v-icon color="white">mdi-plus</v-icon>
                  </span>
                </v-btn>
              </v-flex>
            
          </v-layout>

      </v-flex>
      <v-layout wrap justify-center>
        <v-flex xs12>
        

          <v-layout wrap justify-start v-if="items && items.length > 0">
            <v-flex
              xs12
              sm6
              md4
              lg3
              v-for="(item, itemIndex) in items"
              :key="itemIndex"
              class="d-flex mb-3"
            >
              <v-layout wrap justify-center>
                <v-flex xs12 pa-3>
                  <v-card
                    elevation="5"
                    rounded="lg"
                    class="d-flex flex-column h-100"
                    style="height: 350px;" 
                  >
                    <v-carousel hide-delimiters height="250px">
                      <v-carousel-item
                        v-for="(pic, picIndex) in item.photo"
                        :key="picIndex"
                        :src="mediaURL + pic"
                        reverse-transition="fade-transition"
                        transition="fade-transition"
                      ></v-carousel-item>
                    </v-carousel>
                    
                    
                    <v-flex pa-3 pb-3>
                      <v-flex xs12 pt-1>
                        
                         <div class="d-flex justify-space-between align-center">
                          <span class="tablehead">{{ item.title }}</span>
                          
                         
                        </div>
                      </v-flex>
                      <template v-if="item.description">
                        <v-flex xs12 pt-1 px-4 pb-5 text-justify style="overflow: hidden;">
                          <span class="label">{{ truncateText(item.description, 40).text }}</span>
                          <v-btn icon small v-if="truncateText(item.description, 40).isTruncated" @click="openDialog(item)">
                            <v-icon color="blue">mdi-arrow-right</v-icon> <!-- Right Arrow Icon -->
                          </v-btn>
                        </v-flex>
                      </template>
                      <v-flex text-right>
                        <!-- <v-btn  small  color="success" @click="editSlider(item)">
                          <span style="color: white;">edit</span>
                       </v-btn> -->
                       <v-icon
                       small size="20px"
                       color="primary"
                       class="ml-4"
                       @click.stop="editSlider(item)"
                     >
                       mdi-pencil
                     </v-icon>

                        &nbsp;
                        <!-- <v-btn  small   color="error"  @click="openDeleteDialog(item)">
                          <span style="color: white;">delete</span>
                       </v-btn> -->
                       <v-icon
                       small size="20px"
                       color="error"
                       class="ml-4"
                       @click.stop="openDeleteDialog(item)"
                     >
                       mdi-delete
                     </v-icon>
                      </v-flex>
                    </v-flex>
                    
                  </v-card>
                </v-flex>
              </v-layout>
            </v-flex>
          </v-layout>
          
          

          <v-flex xs12 v-else>
            <v-layout
              wrap
              justify-center
              align-center
             
            >
            <v-flex xs12 class="text-center">
                <span class="nodata">No Data Found !</span>
              </v-flex>
            </v-layout>
          </v-flex>
          <v-flex xs12 pb-4 v-if="items && items.length > 0">
            <v-layout wrap justify-center>
              <v-flex xs10>
                <v-pagination
                  v-model="currentPage"
                  :length="Pagelength"
                  circle
                  color="#427D2D"
                  small
                ></v-pagination>
              </v-flex>
            </v-layout>
          </v-flex>

        </v-flex>
      </v-layout>
      
    </v-layout>


<!-- Modal Dialog for Full Description -->
<v-dialog v-model="dialog" max-width="600px">
  <v-card>
    <v-card-title class="tablehead">{{ selectedItem.title }}</v-card-title>
    <v-card-text class="label" style="color: black;">{{ selectedItem.description }}</v-card-text>
    <v-card-actions>
      <v-btn color="#FF1313" text @click="dialog = false">Close</v-btn>
    </v-card-actions>
  </v-card>
</v-dialog>

    <!-- add dialog -->
    <v-dialog v-model="adddialog" max-width="700px">
      <v-card rounded="lg">
        <v-card-title>
          <span class="heading">ADD IMAGE</span>
          <v-spacer></v-spacer>
          <v-btn color="red" icon @click="adddialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text>
          <v-form>
            <v-layout wrap justify-start pt-2>
              <v-flex xs12 sm12 md12 lg12 class="subheading">
                <span style="color:black">Title</span>
                <v-text-field class="pt-2 text-des" outlined v-model="title" dense hide-details>
                </v-text-field>
              </v-flex>
              <v-flex xs12 sm12 md12 lg12 pt-3 class="subheading">
                <span style="color:black">Description</span>
                <v-textarea outlined v-model="description" dense hide-details class="pt-2 text-des">
                </v-textarea>
              </v-flex>
              <v-flex class="subheading" pt-2 xs12 align-self-center text-left>
                <span style="color:black"> Upload Images</span>
                <v-layout wrap justify-center pa-5 style="border: solid 1px">
                  <v-flex xs12 text-left class="pt-2 text-des">
                    <v-layout wrap justify-start>
                      <v-flex xs12 sm6 md4 lg4 pa-5 v-for="(items, index) in displayImage" :key="index">
                        <v-img :src="items" fill-height height="100%" width="100%">
                          <v-layout wrap>
                            <v-flex text-right pa-0>
                              <v-avatar color="#FF3434" size="15">
                                <v-icon color="#FFF" small @click="removeImage(index)">
                                  mdi-close
                                </v-icon>
                              </v-avatar>
                            </v-flex>
                          </v-layout>
                        </v-img>
                      </v-flex>
                    </v-layout>
                    <v-layout wrap justify-end>
                      <v-flex xs12 text-right>
                        <v-btn color="blue-grey" dark small @click="$refs.uploadDoc.click()"
                          class="itemform"><span> UPLOAD IMAGES </span>
                          <v-icon right dark> mdi-cloud-upload </v-icon></v-btn>
                      </v-flex>
                      <input v-show="false" id="file1" ref="uploadDoc" multiple type="file"
                        accept=".svg, .png, .jpg, .jpeg" @change="uploadDoc" />
                    </v-layout>
                  </v-flex>
                </v-layout>
              </v-flex>
            </v-layout>
          </v-form>
        </v-card-text>
        <v-card-actions class="py-4 justify-end headline  lighten-2">
          <v-btn tile outlined color="#FF1313" light :ripple="false" depressed class="itemValue" text
            @click="adddialog = false"><span style="color: black;">Cancel</span> </v-btn>
          <v-btn tile :color="appColor" light :ripple="false" depressed class="itemValue"
            @click="itemadd()"><span style="color:white;">Save Changes</span> </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog persistent v-model="remarkdialogedit" max-width="700px">
      <v-card rounded="lg">
        <v-card-title>
          <span class="heading">EDIT IMAGE</span>
          <v-spacer></v-spacer>
          <v-btn color="red" icon   @click="remarkdialogedit = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>

        <v-layout wrap>
          <v-flex xs12>
            <v-layout wrap px-5 pb-8>
              <v-flex xs12 align-self-center text-left >
                <span class="subheading"> Title</span>
                <v-text-field
                  class="pt-2 text-des"
                  style="font-size: 14px"
                  v-model="editingitem.title"
                  outlined
                  dense
                  hide-details
                ></v-text-field>
              </v-flex>
              <v-flex xs12 align-self-center pt-2 text-left >
                <span class="subheading"> Description</span>
                <v-textarea
                  class="pt-2 text-des"
                  style="font-size: 14px"
                  v-model="editingitem.description"
                  outlined
                  dense
                  hide-details
                ></v-textarea>
              </v-flex>

              <v-flex
                pt-2
                xs12
                align-self-center
                text-left
                v-if="editingitem.photo && editingitem.photo.length>0"
              >
                <span class="subheading"> Images</span>
                <v-layout pt-2
                  wrap
                  justify-center
                  pa-5
                  style="border: solid 1px"
                  v-if="editingitem.photo"
                >
                  <v-flex xs12 text-left >
                    <v-layout wrap justify-start>
                      <v-flex
                        xs12
                        sm6
                        md4
                        lg4
                        pa-5
                        v-for="(pic, index) in editingitem.photo"
                        :key="index"
                      >
                        <v-img
                          :src="mediaURL + pic"
                          fill-height
                          height="100%"
                          width="100%"
                        >
                          <v-layout wrap>
                            <v-flex text-right pa-0>
                              <v-avatar color="#FF3434" size="15">
                                <v-icon
                                  color="#FFF"
                                  small
                                  @click="(deleteDialogImage = true), (imgItem = pic),(ind=index), (imgItemId = editingitem._id)"
                                >
                                  mdi-close
                                </v-icon>
                              </v-avatar>
                            </v-flex>
                          </v-layout>
                        </v-img>
                      </v-flex>
                    </v-layout>
                  </v-flex>
                </v-layout>
              </v-flex>

              <v-flex pt-2 xs12 align-self-center text-left>
                <span class="itemValue"> Upload Images</span>
                <v-layout wrap justify-center pa-5 style="border: solid 1px">
                  <v-flex xs12 text-left>
                    <v-layout wrap justify-start>
                      <v-flex
                        xs12
                        sm6
                        md4
                        lg4
                        pa-5
                        v-for="(items, index) in displayImage"
                        :key="index"
                      >
                        <v-img
                          :src="items"
                          fill-height
                          height="100%"
                          width="100%"
                        >
                          <v-layout wrap>
                            <v-flex text-right pa-0>
                              <v-avatar color="#FF3434" size="15">
                                <v-icon
                                  color="#FFF"
                                  small
                                  @click="removeImage(index)"
                                >
                                  mdi-close
                                </v-icon>
                              </v-avatar>
                            </v-flex>
                          </v-layout>
                        </v-img>
                      </v-flex>
                    </v-layout>
                    <v-layout wrap justify-end>
                      <v-flex xs12 text-right>
                        <v-btn
                          color="blue-grey"
                          dark
                          small
                          @click="$refs.uploadDoc.click()"
                          class="itemform"
                          ><span> UPLOAD IMAGES </span>
                          <v-icon right dark> mdi-cloud-upload </v-icon></v-btn
                        >
                      </v-flex>
                      <input
                        v-show="false"
                        id="file1"
                        ref="uploadDoc"
                        multiple
                        type="file"
                        accept=".svg, .png, .jpg, .jpeg"
                        @change="uploadDoc"
                      />
                    </v-layout>
                  </v-flex>
                </v-layout>
              </v-flex>
            </v-layout>
          </v-flex>
         
        </v-layout>
         <v-card-actions class="py-4 justify-end headline  lighten-2">
            <v-btn tile outlined color="#FF1313" light :ripple="false" depressed class="itemValue" text
            @click="remarkdialogedit = false"><span style="color: black;">Cancel</span> </v-btn>
            <v-btn tile :color="appColor" light :ripple="false" depressed class="itemValue"
            @click="editSubmitRemark()"><span style="color:white;">Save Changes</span> </v-btn>
          </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="deleteDialogImage" max-width="500">
      <v-card>
        <v-card-title class="heading">Delete Image</v-card-title>
        <v-card-text>
          Are you sure you want to delete this Image?
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn tile outlined color="#FF1313" light :ripple="false" depressed   @click="deleteDialogImage = false"
            class="itemValue">
            Cancel
          </v-btn>
          <v-btn tile :color="appColor" light :ripple="false" depressed @click="deleteImage(imgItem)"  class="itemValue">
            <span style="color: #fff">Confirm</span>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="deleteDialog" max-width="500">
      <v-card>
        <v-card-title class="heading">Confirmation</v-card-title>
        <v-card-text>Are you sure you want to delete this Image?</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            tile
            outlined
            color="#FF1313"
            light
            :ripple="false"
            depressed
            @click="deleteDialog = false"
            class="itemValue"
          >
            Cancel
          </v-btn>
          <v-btn
            tile
            :color="appColor"
            light
            :ripple="false"
            depressed
             @click="confirmDelete"
            class="itemValue"
          >
            <span style="color: #fff">Submit</span>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  





    
  </div>
</template>
<script>
import axios from "axios";
//import OwlCarousel from 'vue-owl-carousel';

export default {
  // components: {
  //   OwlCarousel,

  // },
  data() {
    return {
      imgItem: "",
      deleteDialogImage: false,
      remarkdialogedit: false,
      dialog: false, 
      selectedItem: {},
      title: "",
      description: "",
      appLoading: false,
      ServerError: false,
      showSnackBar: false,
      timeout: 5000,
      msg: null,

      keyword: "",
      page: 1,
      currentPage: 1,
      pages: 0,
     // limit: 10,
      //userlist: [],
      items: [],
      status: "",
      deleteDialog: false,
      deleteid: "",
      rejectDialog: false,
      rejectid: "",
      addEventListenerdialog: false,
      adddialog: false,
      editdialog: false,
      editingitem: [],
      // photo: [],

      ind:"",

      docs1: [],
      docs1Public: [],
      docsPublic: [],
      docs: [],
      displayImage: [],
     // userList: [],
      userArray: [],
      newArray: [],
      doc: null,
      file: null,

      registerAssets: {},
      formData: new FormData(),
      assetId: "",
      search: "",
      Rules: [(value) => !!value || "Required."],
      fileArray: [],
      byte: "",
      kb: "",
      mb: "",
      gb: "",
      isHovered: {} ,
      owlResponsive: {
        0: { items: 1, nav: false },
        600: { items: 1, nav: false },
        960: { items: 1, nav: false },
        1264: { items: 1, nav: false },
        1920: { items: 1, nav: false },
      },
    };
  },
  mounted() {
    this.getData(); // Fetch data when component mounts
  },

  watch: {
    currentPage() {
      this.getData();
    },
    count() {
      this.getData();
    },
    keyword() {
      this.getData();
      this.currentPage=1
    },
  },
  methods: {
    
    clearForm() {
      this.description = "";
      this.displayImage = [];
      this.title = "";
      this.docs = [];
      this.fileArray = [];
    },
    deleteImage(imgItem) {
  this.appLoading = true;
  this.editingitem.photo.splice(this.ind,1)
  axios({
    method: "POST",
    url: "/resource/image/delete/single",
    headers: {
      "x-auth-token": localStorage.getItem("token"),
    },
    data: {
      photo: imgItem,    // image being deleted
      id: this.imgItemId // id of the item containing the image
    },
  })
    .then((response) => {
      if (response.data.status) {
            this.msg = response.data.msg;
            this.showSnackBar = true;
            this.deleteDialogImage = false;

            this.getData();
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
          }
    })
    .catch((err) => {
      this.ServerError = true;
      console.log(err);
    });
},
    editSlider(item) {
      this.editingitem = item;
      this.remarkdialogedit = true;
    },
    editSubmitRemark() {

      if (!this.editingitem.title) {
    this.msg = "Please provide a title.";
    this.showSnackBar = true;
    return;
  }
  
  // Validate description
  if (!this.editingitem.description) {
    this.msg = "Please provide a description.";
    this.showSnackBar = true;
    return;
  }
  
  // Validate images - Check if both existing images and newly uploaded images are empty
  if ((!this.editingitem.photo || this.editingitem.photo.length===0) && (!this.displayImage || this.displayImage.length === 0)) {
    this.msg = "Please add at least one image.";
    this.showSnackBar = true;
    return;
  }


      let formData = new FormData();

      formData.append("description", this.editingitem.description);
      formData.append("title", this.editingitem.title);
      formData.append("resourceId",  this.editingitem._id);
      for (let i = 0; i < this.docs.length; i++) {
        formData.append("photo", this.docs[i]);
      }

      this.appLoading = true;
      axios({
        method: "POST",
        url: "/resource/edit/image",
        headers: {
          "Content-Type": "multipart/form-data",
          "x-auth-token": localStorage.getItem("token"),
        },
        data: formData, // Use the local formData object, not this.formData
      })
        .then((response) => {
          if (response.data.status) {
            this.msg = response.data.msg;
            this.showSnackBar = true;
            this.remarkdialogedit = false;
            this.getData();
            this.docs=[];
            this.displayImage=[];
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },

    truncateText(text, length) {
      if (text && text.length > length) {
        return { text: text.substring(0, length) + '...', isTruncated: true };
      } else if (text) {
        return { text: text, isTruncated: false };
      } else {
        return { text: '', isTruncated: false }; // Return empty string if text is undefined
      }
    },
    openDialog(item) {
      // Set the selected item and open the dialog
      this.selectedItem = item;
      this.dialog = true;
    },
   
    uploadDoc(event) {
      this.docs1 = event.target.files || event.dataTransfer.files;

      if (this.docs.length < 10) {
        for (let i = 0; i < this.docs1.length; i++) {
          if (this.fileArray.includes(this.docs1[i].name)) {
            this.msg = "Duplicate File";
            this.showSnackBar = true;
          } else {
            const urls = URL.createObjectURL(this.docs1[i]);
            this.displayImage.push(urls);
            this.docs.push(this.docs1[i]);
          }
          this.fileArray.push(this.docs1[i].name);
        }
      } else {
        this.msg = "Maximum 10 images can be uploaded";
        this.showSnackBar = true;
      }
    },
    closeDialog() {
      this.editdialog = false;
      this.getData();
    },
    getData() {
      this.appLoading = true;
      axios({
        method: "post",
        url: "/resource/list",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
        data: {
          isVideoLink:false,
          count: this.count,
          page: this.currentPage,
         
        },
      })
        .then((response) => {
          if (response.data.status == true) {
            {
              this.items = response.data.data;
              this.Pagelength = response.data.pages;
              this.msg = response.data.msg;
              this.showSnackBar = false;
              this.appLoading = false;
            }
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
            this.appLoading = false;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          (this.ServerError = true), console.log(err);
        });
    },

    itemadd() {

      if (!this.title) {
    this.msg = "Please Provide title.";
    this.showSnackBar = true;
    return;
  }
  if (!this.description) {
    this.msg = "Please Provide description.";
    this.showSnackBar = true;
    return;
  }
  if (!this.displayImage || this.displayImage.length === 0) {  
    this.msg = "Please add at least one image.";
    this.showSnackBar = true;
    return;
  }

      let formData = new FormData();
      formData.append("title", this.title);
      formData.append("description", this.description);

      for (let i = 0; i < this.docs.length; i++) {
        formData.append("photo", this.docs[i]);
      }
      this.appLoading = true;
      axios({
        method: "POST",
        url: "/resource/addimage",
        headers: {
          "Content-Type": "multipart/form-data",
          "x-auth-token": localStorage.getItem("token"),
        },
        data: formData,
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status) {
            this.msg = response.data.msg;
            this.showSnackBar = true;
            this.adddialog = false;

            this.title = null;
            this.description = null;
            this.photo=null,
            this.clearForm();
            this.getData();
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          (this.ServerError = true), console.log(err);
        });
    },


    openDeleteDialog(item) {
      this.itemToDelete = item;
      this.deleteDialog = true;
    },
    confirmDelete() {
      if (this.itemToDelete) {
        this.deleteItem(this.itemToDelete);
      }
      this.deleteDialog = false;
    },
    deleteItem(r) {
      var data = {};
      data["id"] = r._id;
      axios({
        url: "/resource/image/delete",
        method: "POST",
        data: data,
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.deleteDialog = false;
          this.appLoading = false;
          if (response.data.status) {
            this.msg = response.data.msg;
            this.showSnackBar = true;
            this.getData();
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },

    // opendeleteDialog(item) {
    //   this.deleteid = item._id;
    //   this.deleteDialog = true;
    // },
    // deleteoperation() {
    //   this.appLoading = true;
    //   axios({
    //     method: "POST",
    //     url: "/resource/image/delete",
    //     headers: {
    //       "x-auth-token": localStorage.getItem("token"),
    //     },
    //     data: {
    //       id: this.deleteid,
    //     },
    //   })
    //     .then((response) => {
    //       this.deleteDialog = false;
    //       this.appLoading = false;
    //       if (response.data.status) {
    //         this.msg = response.data.msg;
    //         this.showSnackBar = true;
    //         this.getData();
    //       } else {
    //         this.msg = response.data.msg;
    //         this.showSnackBar = true;
    //       }
    //     })
    //     .catch((err) => {
    //       this.appLoading = false;
    //       this.ServerError = true;
    //       console.log(err);
    //     });
    // },
    removeImage(index) {
      this.displayImage.splice(index, 1);
      this.docs.splice(index, 1);
      this.fileArray.splice(index, 1);
    },

    //   rejectoperation() {
    //     this.appLoading = true;
    //     axios({
    //       method: "POST",
    //       url: "/operation/removedeleterequest",
    //       headers: {
    //         "x-auth-token": localStorage.getItem("token"),
    //       },
    //       data: {
    //         id: this.rejectid,
    //       },
    //     })
    //       .then((response) => {
    //         if (response.data.status == true) {
    //           {
    //             this.msg = "Rejected Successfully.";
    //             this.showSnackBar = true;
    //             this.appLoading = false;
    //             this.rejectDialog=false;
    //           }
    //         } else {
    //           this.msg = response.data.msg;
    //           this.showSnackBar = true;
    //           this.appLoading = false;
    //           this.rejectDialog=false;

    //         }
    //       })
    //       .catch((err) => {
    //         this.appLoading = false;
    //         this.ServerError = true;
    //         console.log(err);
    //       });
    //   },
  },
};
</script>
<style>
.heading {
  font-family: poppinsregular;
  font-size: 20px;
  font-weight: 900;
}

.subheading {
  font-family: poppinsregular;
  font-size: 15px;
  font-weight: 500;
}

.zoom-container {
  position: relative;
  overflow: hidden;
}

.card-image {
  border-radius: 15px;
  height: 250px;
  /* Adjust height as needed */
  transition: transform 0.3s ease;
  /* Add transition for zoom effect */
}

.zoomed {
  transform: scale(1.2);
  /* Zoom effect when mouse hovers over the image */
}


</style>